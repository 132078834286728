import './index.scss';
import logo from './images/PP_woordmerk.png'
import { Link } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { browserName, browserVersion } from "react-device-detect";

function App(props) {
  if (browserName === 'Mobile Safari' && browserVersion < 13) {
    return (
      <Div100vh className="app">
      <div className='border-container'>
        <img src={logo} alt="Paaspop Logo" className='logo-big' />
        <div className='text-container'>
          <h1>Sorry! Update je apparaat of gebruik Google Chrome omdat wij op deze versie van safari je niet de ultieme experience kunnen bieden!</h1>
      </div>
      </div>
    </Div100vh>
    );
  } if (browserName === 'Safari' && browserVersion < 15 ) {
    return (
      <Div100vh className="app">
      <div className='border-container'>
        <img src={logo} alt="Paaspop Logo" className='logo-big' />
        <div className='text-container'>
          <h1>Sorry! Update je apparaat of gebruik Google Chrome omdat wij op deze versie van safari je niet de ultieme experience kunnen bieden!</h1>
      </div>
      </div>
    </Div100vh>
    );
  } else {
  return (
    <Div100vh className="app">
      <div className='border-container'>
        <img src={logo} alt="Paaspop Logo" className='logo-big' />
        <div className='text-container'>
          <h1>Maak je eigen line-up poster!</h1>
          <p>Stel je voor: jij bent programmeur van Paaspop. Wie mag er echt niet ontbreken op jouw poster? En welke act mogen wij absoluut niet missen, dit jaar? Kortom: hoe ziet jouw Paaspop Wishlist eruit?</p>
          <p>Dit jaar denk jij mee. En wij luisteren graag! Kies je artwork, je namen en deel jouw Paaspop Wishlist - in postervorm! - met de rest van de wereld.</p>
          <p>Het mooie? Onder de deelnemers verloten we 2x2 Paaspop Ultimate Experience Tickets!</p>
          <p>Laat van je horen! We zijn benieuwd.</p>
        </div>
        <Link to='/create-poster' className='btn'>Start Here</Link>
      </div>
    </Div100vh>
  );}
}

export default App;
