import React, {useEffect, useRef, useState, useContext} from 'react'
// eslint-disable-next-line
import logo from '../images/PP_woordmerk.png'
import divider from '../images/divider.svg'
import dividerH from '../images/dividerH.svg'
import arrow from '../images/PP_arrow.svg'
import arrowB from '../images/PP_arrow_black.svg'
import share from '../images/share.svg'
import loader from '../images/loader.svg'
import download from '../images/download.svg'
import BGImage from '../images/Kies_thema.png'
import stars from '../images/stars.png'
import PPinfo from '../images/PP_info.png'

import BlockItem from '../components/BlockItem'
import { selectedArtistsContext } from '../context/SelectedArtistsList'

import { TagsInput } from "react-tag-input-component";

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import firebase from 'firebase/compat/app'

import Confetti from 'react-confetti';

import {gsap} from 'gsap'

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, useInstantSearch } from 'react-instantsearch-hooks-web';
import { useInfiniteHits } from 'react-instantsearch-hooks-web';
import { getSecondPart } from '../functions/stringSplitting';

import Div100vh from 'react-div-100vh'

import html2canvas from 'html2canvas'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { isMobile, browserName, browserVersion } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

const searchClient = algoliasearch('T0CVO3901F', 'a5231e41f66007b56a0823541bac2477')

const initialState = {
  ID: '',
  artist: [],
  theme: {},
  user: {
    fname: '',
    lname: '',
    mail: '',
    optIn: '0'
  }
}

const Createposter = (props) => {
  const history = useNavigate()
  const [poster, setPoster] = useState(initialState)
  const [user, setUser] = useState(initialState.user)// eslint-disable-next-line
  const [theme, setTheme] = useState(initialState.theme)// eslint-disable-next-line
  const [bgImage, setBgImage] = React.useState(BGImage)

  const [confetti, setConfetti] = useState(false)
  const [confettiRecycle, setConfettiRecycle] = useState(false)

  const [loading, setLoading] = useState(false)

  const {artist, handleArtistChange2, handleArtistRemoved} = useContext(selectedArtistsContext);

  const userFormRef = useRef();
  const themeFormRef = useRef();
  const artistFormRef = useRef();
  const endResultRef = useRef();
  const posterRef = useRef();
  const endResultContentRef = useRef();
  const optInRef = useRef();
  const mailRef = useRef();

  gsap.set(posterRef.current, {perspective: 800, transformStyle: 'preserve-3d'})
  
  const getBGImage = (id) => {
    firebase.firestore().collection('themes').doc(id).get().then((doc) => {
      if (doc.exists) {
        setBgImage(doc.data().posterImg)
      } else {
        // console.log('No such document!')
      }
    })
  }
  const handleDownloadImage = async () => {
    const element = posterRef.current
    const canvas = await html2canvas(element, {
      useCORS: true,
      allowTaint: true,
      letterRendering: true,
      scale: 3,
      backgroundColor: null,
      logging: true,
      onrendered: function (canvas) {
        var ctx = canvas.getContext('2d');
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
      }
    })

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'PP_poster.png';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  }
  const handleShare = async () => {
    setLoading(true)
    const canvas = await html2canvas(posterRef.current, {
      useCORS: true,
      allowTaint: true,
      letterRendering: true,
      scale: 3,
      backgroundColor: null,
      logging: true,
      onrendered: function (canvas) {
        var ctx = canvas.getContext('2d');
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
      }
    })

    const dataURL = canvas.toDataURL();
    const blob = await (await fetch(dataURL)).blob();

    const filesArray = [
      new File([blob], 'PP_Poster.png', {
          type: blob.type,
        }
      )
    ]

    const data = {
      files: filesArray
    };

    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);
      setLoading(false)
    } catch (err) {
      console.error(err);
    }
  };
// eslint-disable-next-line
  const handleUserChange = (e) => {
    const {name, value} = e.target
    setUser({...user, [name]: value})
  }

  const handleUserCheckboxChange = (e) => {
    const {name, checked} = e.target
    setUser({...user, [name]: checked ? '1' : '0'})
  }

  const handleUserSubmit = (e) => {
    e.preventDefault()
    if (user.fname !== '' && user.lname !== '') {
      if (user.optIn === '1') {
        if (user.mail !== '' && user.mail.includes('@') && user.mail.includes('.')) {
          setPoster({...poster, user})
          gsap.to(userFormRef.current, {x: '-100%', duration: 1})
          gsap.to(themeFormRef.current, {x: '-100%', duration: 0.5})
          gsap.to(artistFormRef.current, {x: '-100%', duration: 0.5})
          gsap.to(endResultRef.current, {x: '-100%', duration: 0.5})
        } else {
          toast.error('Vergeet niet je mail correct in te vullen!')
        }
      } else {
        setPoster({...poster, user})
        gsap.to(userFormRef.current, {x: '-100%', duration: 1})
        gsap.to(themeFormRef.current, {x: '-100%', duration: 0.5})
        gsap.to(artistFormRef.current, {x: '-100%', duration: 0.5})
        gsap.to(endResultRef.current, {x: '-100%', duration: 0.5})
      }
    }else if (user.fname === '') {
      toast.error('Vergeet niet je voornaam in te vullen!')
    }
    else if (user.lname === '') {
      toast.error('Vergeet niet je achternaam in te vullen!')
    } else {
      toast.error('Vergeet niet je gegevens in te vullen!')
    }
   
    

  }

  const handleThemeSubmit = (e) => {
    e.preventDefault()
    setPoster({...poster, theme: theme})
    if (typeof theme !== 'object') {
      gsap.to(themeFormRef.current, {x: '-200%', duration: 1})
      gsap.to(artistFormRef.current, {x: '-200%', duration: 0.5})
      gsap.to(endResultRef.current, {x: '-200%', duration: 0.5})
    } else {
      toast.error('Selecteer een thema!', {
        autoClose: 1000,
      })
    }
  }

  const handleArtistSubmit = async (e) => {
    e.preventDefault()
    setPoster({...poster, artist: artist})
    if (artist.length > 0) {
      gsap.to(artistFormRef.current, {x: '-300%', duration: 0.5})
      gsap.to(endResultRef.current, {x: '-300%', duration: 0.5})
      setConfetti(true)
      setConfettiRecycle(true)

      gsap.timeline()
        .to(posterRef.current, {rotateY: 720, duration: 4, ease: 'back.inOut'})
        .to(posterRef.current, {scale: 0.9, duration: 1, ease: 'back.inOut'})
        .fromTo(endResultContentRef.current, {opacity: 0, y: 0}, {opacity: 1, y: -10, duration: 1, ease: 'back.inOut'})
        .eventCallback('onComplete', () => {
          setConfettiRecycle(false)
        })

        const res = await firebase.firestore().collection('posters').add(
          {
            user: user,
            theme: theme,
            artist: artist,
          }
        )

        firebase.firestore().collection('posters').doc(res.id).update({
          ID: res.id
        })
    } else {
      toast.error('Selecteer minstens 1 artiest!', {
        autoClose: 1000,
      })
    }
  }

  const handleArtistBackSubmit = (e) => {
    e.preventDefault()
    setPoster({...poster, artist: artist})
    gsap.to(themeFormRef.current, {x: '-100%', duration: 0})
    gsap.to(artistFormRef.current, {x: '-100%', duration: 1})
  }

  const handleArtist = (e) => {
    handleArtistChange2(e)
  }

  const handleRemoved = (e) => {
    handleArtistRemoved(e)
  }

  const selectedTheme = (id) => {
    setTheme(id)
    toast.success('Thema geselecteerd!', {
      autoClose: 1000
    })
  }

  useEffect(() => {
    if (typeof theme !== 'object') {
      getBGImage(theme)
    }

    if (user.optIn === '1') {
      gsap.to(optInRef.current, {transform: 'translateY(10px)', duration: 0.5})
      gsap.to(mailRef.current, {opacity: 1, duration: 0.5, delay: 0.5})
    } else{
      gsap.to(mailRef.current, {opacity: 0, duration: 0.5})
      gsap.to(optInRef.current, {transform: 'translateY(-70px)', duration: 0.5, delay: 0.5})
    }

  }, [user,theme])

  if (browserName === 'Mobile Safari' && browserVersion < 13) {
    history('/')
  }
  if (browserName === 'Safari' && browserVersion < 15 ) {
    history('/')
  }
  else{
  return (
    <Div100vh>
    <div className='app alt'>
      {/* {console.log(browserName, browserVersion)} */}
      <ToastContainer position='top-center'/>
      <Confetti numberOfPieces={300} run={confetti} recycle={confettiRecycle} gravity={0.04} />
      {/* <div className='information-block'>
        <div>
          <p style={{fontWeight: 'bold'}}>STATES</p>
          {typeof theme !== 'object' ? <p>thema: {theme}</p>: <p>thema: </p>}
          <p>Voornaam: {user.fname}</p>
          <p>Achternaam: {user.lname}</p>
          <p>Email: {user.mail}</p>
          <p>OPT-IN: {user.optIn}</p>
          {artist.length > 0 ? <p>Artiesten: {artist.map((art) => art + ', ')}</p> : <p>Artiesten: </p>}
        </div>
        <div>
          <p style={{fontWeight: 'bold'}}>END POSTER</p>
          {typeof poster.theme !== 'object' ? <p>thema: {poster.theme}</p>: <p>thema: </p>}
          <p>Voornaam: {poster.user.fname}</p>
          <p>Achternaam: {poster.user.lname}</p>
          <p>Email: {poster.user.mail}</p>
          <p>OPT-IN: {poster.user.optIn}</p>
          {artist.length > 0 ? <p>Artiesten: {poster.artist.map((art) => art + ', ')}</p> : <p>Artiesten: </p>}
        </div>
      </div> */}
      <section id='userForm' ref={userFormRef}>
        <img src={logo} alt="Paaspop Logo" className='logo-small' />
        <div className='text-container' style={{maxWidth: '800px'}}>
          <h1>Wie ben je?</h1>
          <p>Laat je gegevens achter om jouw poster te personaliseren. Een e-mailadres is niet verplicht, maar het mag natuurlijk wél. Zo ben jij direct op de hoogte van all things Paaspop!</p>
          <form>
            <div className='input-container small'>
              <label htmlFor='fname'>Voornaam</label>
              <input type='text' id='fname' name='fname' onChange={handleUserChange}/>
            </div>
            <div className='input-container small'>
              <label htmlFor='lname'>Achternaam</label>
              <input type='text' id='lname' name='lname' onChange={handleUserChange}/>
            </div>
            <div className='input-container big' id='mail-block' ref={mailRef}>
              <label htmlFor='mail'>Mailadres</label>
              <input type='email' id='mail' name='mail' onChange={handleUserChange}/>
            </div>
            <div className='input-container big' id='optInCheckbox' ref={optInRef}>
              <label className='checkbox-container'>Ja, ik wil de nieuwsbrief van Paaspop ontvangen en laat graag mijn e-mailadres achter.
                <input type='checkbox' id='optIn' name='optIn' onChange={handleUserCheckboxChange}/>
                <span className='checkmark'></span>
              </label>
            </div>
          </form>
        </div>
        <button className='btn' onClick={handleUserSubmit}>Meld je aan</button>
      </section>
      <section id='themeForm' ref={themeFormRef}>
        <div className='appGrid'>
          <div id='themakies' className='gridL'>
            <div className='text-container' style={{maxWidth: '100%'}}>
              <h1>Kies jouw artwork</h1>
              <p>Dit wordt de achtergrond van jouw poster.</p>
            </div>
            {props.themes !== [] && 
              <div className='theme-container'>
                {props.themes && props.themes.map((theme) => {
                  return (
                    <button style={{background: 'transparent', border: 'none', color: '#efefef'}} onClick={() => {selectedTheme(theme.id)}}>
                      <BlockItem content={{name: theme.themeName, previewImg: theme.previewImg, id: theme.ID}} />
                    </button>
                  )
                })}
              </div>
            }
            <div className='btn-container'>
              {isMobile ? 
                <button className='iconOnlyBtnRound' onClick={handleThemeSubmit}>
                  <img src={arrow} alt='arrow' />
                </button>
                :
                <button className='iconBtnRound red' onClick={handleThemeSubmit}>
                  Volgende <img src={arrow} alt='arrow' />
                </button>
              }
              {isMobile &&
              <a href='#themavoorbeeld' className='iconBtnRound'>
                Voorbeeld<img src={arrowB} alt='arrow' style={{transform: 'rotate(90deg)'}} />
              </a>
}
            </div>
          </div>
          {isMobile &&
            <div className='divider'>
              <img src={dividerH} alt="Divider" />
            </div>
          }
          {!isMobile &&
            <div className='divider'>
              <img src={divider} alt="Divider" />
            </div>
          }
          <div id='themavoorbeeld' className='gridR'>
            {poster.theme !== {} && 
              <div className='poster-container' style={{position: 'relative' ,aspectRatio: '9 / 16' , height: '85%', border: 'none', pointerEvents: 'none' }}>
              <img className='posterImage' src={bgImage} alt='bg' style={{width: '100%', position: 'absolute'}}/>
              </div>
            }
            {isMobile &&
            <div className='btn-container'>
              <button className='iconOnlyBtnRound' onClick={handleThemeSubmit}>
                <img src={arrow} alt='arrow' />
              </button>
              <a href='#themakies' className='iconBtnRound'>
                Back to top<img src={arrowB} alt='arrow' style={{transform: 'rotate(-90deg)'}} />
              </a>
            </div>
}
            </div>
        </div>
      </section>
      <section id='artistForm' ref={artistFormRef}>
        <div className='appGrid'>
          <div id='artistkies' className='gridL'>
            <div className='text-container' style={{maxWidth: '100%'}}>
              <h1>Kies jouw TOP 5</h1>
              <p>Hieronder zie je artiesten die door de jaren heen op Paaspop speelden. Selecteer jouw TOP 5.</p>
              <p>Staat je artiest er niet tussen? Vul deze in bij ‘overig’ en druk op enter/return. Klaar? {isMobile ? 'Druk op het pijltje naar rechts!': 'Druk op volgende!'}</p>
            </div>
              <InstantSearch searchClient={searchClient} indexName="artists">
                <SearchBox placeholder='Zoek hier je favoriete artiest' />
                <NoResultsBoundary fallback={<NoResults />}>
                  <InfiniteHits />
                </NoResultsBoundary>
              </InstantSearch>
              <TagsInput classNames='taginput' name='tagInput' placeHolder='Staat jouw favoriet er niet tussen? Voeg hier toe!'
              onChange={handleArtist}
              onRemoved={handleRemoved}
              onExisting={handleRemoved}
              disabled={artist.length >= 5}
              seprators={[',','Enter']}
              />
            <div className='btn-container'>
              {/* <div> */}
                <button className='iconOnlyBtnRound' onClick={handleArtistBackSubmit}>
                  <img src={arrow} alt='arrow' style={{transform: 'rotate(180deg)'}}/>
                </button>
                {isMobile ? 
                <button className='iconOnlyBtnRound' onClick={handleArtistSubmit}>
                  <img src={arrow} alt='arrow' />
                </button>
                :
                <button className='iconBtnRound red' onClick={handleArtistSubmit}>
                  Volgende <img src={arrow} alt='arrow' />
                </button>
              }
              {/* </div> */}
              {isMobile &&
              <a href='#artistvoorbeeld' className='iconBtnRound'>
                Voorbeeld<img src={arrowB} alt='arrow' style={{transform: 'rotate(90deg)'}} />
              </a>
}
            </div>
          </div>
          {isMobile &&
            <div className='divider'>
              <img src={dividerH} alt="Divider" />
            </div>
          }
          {!isMobile &&
            <div className='divider'>
              <img src={divider} alt="Divider" />
            </div>
          }
          <div id='artistvoorbeeld' className='gridR'>
            {poster.theme !== {} && 
              <div className='poster-container' style={{position: 'relative' ,aspectRatio: '9 / 16' , minHeight: '550px' , height: '75%', border: 'none', pointerEvents: 'none'}}>
                <img className='posterImage' src={bgImage} alt='bg' style={{width: '100%', position: 'absolute'}}/>
                <div className='poster-content'>
                  <div id='poster-content-personal-text'>
                    <h2 className='poster-content-topTitle'>{poster.user.fname.charAt(poster.user.fname.length - 1) === 's' ? poster.user.fname : poster.user.fname + `'s`}</h2>
                    <p>wishlist</p>
                  </div>
                  <img className='poster-content-stars' src={stars} alt='sterren' />
                  <div id='poster-content-artists'>
                  {artist.map((artist) => {
                    return (
                      <h3 className='artistName'>
                        {artist.includes(':') ? getSecondPart(artist, ':') : artist}
                      </h3>
                    )
                  })}
                  </div>
                  <img className='poster-content-stars' src={stars} alt='sterren' />
                  <img className='poster-content-PP' src={PPinfo} alt='7, 8 en 9 april Paaspop Schijndel' />
                </div>
              </div>
            }

            {isMobile &&
            <div className='btn-container'>
              <div>
                <button className='iconOnlyBtnRound' onClick={handleArtistBackSubmit}>
                  <img src={arrow} alt='arrow' />
                </button>
                <button className='iconOnlyBtnRound' onClick={handleArtistSubmit}>
                  <img src={arrow} alt='arrow' />
                </button>
              </div>
              <a href='#artistkies' className='iconBtnRound'>
                Back to top<img src={arrowB} alt='arrow' style={{transform: 'rotate(-90deg)'}} />
              </a>
            </div>
}
            </div>
        </div>
      </section>
      <section id='endResult' ref={endResultRef}>
        <div className='appGrid' style={{overflow: 'hidden'}}>
          <div id='posterresult' className='gridR'>
            {poster.theme !== {} && 
              <div ref={posterRef} className='poster-container' style={{position: 'relative' ,aspectRatio: '9 / 16' , minHeight: '75%', border: 'none', pointerEvents: 'none' , marginTop: '20px' }}>
              <img className='posterImage' src={bgImage} alt='bg' style={{width: '100%', position: 'absolute'}}/>
              <div className='poster-content'>
                  <div id='poster-content-personal-text'>
                    <h2 className='poster-content-topTitle'>{poster.user.fname.charAt(poster.user.fname.length - 1) === 's' ? poster.user.fname : poster.user.fname + `'s`}</h2>
                    <p>Wishlist</p>
                  </div>
                  <img className='poster-content-stars' src={stars} alt='sterren' />
                  <div id='poster-content-artists'>
                  {artist.map((artist) => {
                    return (
                      <h3 className='artistName'>
                        {artist.includes(':') ? getSecondPart(artist, ':') : artist} 
                      </h3>
                    )
                  })}
                  </div>
                  <img className='poster-content-stars' src={stars} alt='sterren' />
                  <img className='poster-content-PP' src={PPinfo} alt='7, 8 en 9 april Paaspop Schijndel' />
                </div>
            </div>
            }
            <div className='endResultContent' ref={endResultContentRef}>
            {isMobile && 
              <div className='btn-container center'>
                {loading ? <button className='iconBtnRound alt' onClick={handleShare} style={{backgroundColor: '#FFB82E'}}>
                  Loading<img src={loader} alt='laad icoontje' />
                </button> : <button className='iconBtnRound alt' onClick={handleShare} style={{backgroundColor: '#FFB82E'}}>
                  Share or Download<img src={share} alt='download icoontje' />
                </button>}
              </div>
            }
              <h2>BEDANKT!</h2>
              <p>Wellicht zie je jouw wishlist wel terug tijdens de release van de eerste namen van Paaspop 2023 op dinsdag 15 november. Staat jouw favoriete artiest op Paaspop 2023? Zorg dan dat je erbij bent, op zaterdag 19 november start de reguliere kaartverkoop!</p>
              <h3>Kans maken op Ultimate Experience Tickets?</h3>
              <p>
                  - Download<br />
                  - Volg Paaspop op Instagram en Facebook.<br />
                  - Deel jouw wishlist op je socials<br />
                  - Tag @paaspopfestival
              </p>
              <p>De winnaar maken we bekend op maandag 14 november!</p>
              {!isMobile &&
              <div className='btn-container'>
                  <button className='iconBtnRound alt' onClick={handleDownloadImage}>
                  Download<img src={download} alt='arrow' />
                  </button>
              </div>
            }
            </div>
            </div>
        </div>
      </section>
      
    </div>
    </Div100vh>
  )}
}

export function InfiniteHits(props) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);
  const {handleArtistChange} = useContext(selectedArtistsContext);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  });

  return (
    <div ref={sentinelRef} aria-hidden="true" className="artist-container">
        {hits.map((hit) => (
            <button key={hit.objectID} style={{background: 'transparent', border: 'none', color: '#efefef'}} onClick={() => {handleArtistChange(hit.objectID + ':' + hit.artistName)}}>
              <BlockItem content={{name: hit.artistName, previewImg: hit.previewImg, id: hit.objectID}} shape='round' overlay={true}/>
            </button>
        ))}
    </div>
  );
}

function NoResultsBoundary({ children, fallback }) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned yet.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

function NoResults() {
  const { indexUiState } = useInstantSearch();

  return (
    <div>
      <p className='text-center'>
        Geen artiest genaamd <q>{indexUiState.query}</q> gevonden in de afgelopen Paaspop-edities. Vul jouw favoriete artiest hieronder in en wellicht kom je <q>{indexUiState.query}</q> wel tegen op Paaspop 2023!
      </p>
    </div>
  );
}

// eslint-disable-next-line
const mapStateToProps = (state) => {
  return {
    themes: state.firestore.ordered.themes,
    artists: state.firestore.ordered.artists
  }
}


export default compose(
  connect(mapStateToProps, null), 
  firestoreConnect([
    { collection: 'themes', },
    { collection: 'artists', },
  ])
)(Createposter)
